import Rest from "@/services/Rest";

/**
 * @typedef {FunnelTemplate}
 */
export default class FunnelTemplate extends Rest {
  /**
   * @type {String}
   */
  static resource = "templates-funnels";
}
