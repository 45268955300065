<template>
  <div>
    <CampanhasCriar></CampanhasCriar>
  </div>

</template>

<script>
import BaseView from "@/template/BaseView.vue";
import CampanhasCriar from "@/components/Campanhas/CampanhasCriar.vue";

export default {
  components: {
    BaseView,
    CampanhasCriar,
  },
  // created() {
  //   if (this.$store.getters.user.user.level == 'blogger') {this.$router.push("/dynamicRoute/AccessDenied")};
  // },
};
</script>
