<template>
  <div class="max-container">
    <div class="Campanha-container">
      <h4 class="titulo">{{ stepFunil == 1 ? 'Criação de funil' : stepFunil == 2 ? 'Escolha um template' : 'Confira os dados' }}</h4>
      <p>{{ stepFunil == 1 ? 'Vamos inicar a criação do seu funil de vendas' : stepFunil == 2 ? 'Selecione o template de sua preferência' : 'Verifique se os dados estão corretos' }} </p>
      <div class="box-Campanha">
        <b-form-group>
          <h3 class="stepFunil" @click="stepFunil == 1 ? '' : changeStep(1)" :style="stepFunil == 1 ? '' : 'cursor:pointer;'">Etapa 01</h3>
          <label class="sub-title" :class="stepFunil == 1 ? '' : 'past-sub-title'">Como vai se chamar o seu funil?</label>
          <div class="Campanha-nome">
            <input
              :disabled="stepFunil != 1"
              type="text"
              name="Campanha_nome"
              id="Campanha_nome"
              placeholder="Nome do funil"
              v-model="nomeCampanha"
              style="transition: all 0.3s;"
              :class="stepFunil == 1 ? '' : 'input-off'"
            />
            <span class="titleError text-danger" v-show="titleRequired"
              >Título obrigatório</span
            >
            <span class="titleError text-danger" v-if="titleSpace"
              >Espaços inválidos</span
            >
            <span
              class="titleError text-danger"
              v-show="titleLength"
              v-if="nomeCampanha.length < 6 || nomeCampanha.length > 40"
              >Mínimo de 6 caracteres e máximo de 40 caracteres</span
            >
          </div>
          <b-collapse v-model="validImage">
            <label class="sub-title" :class="stepFunil == 1 ? '' : 'past-sub-title'">Adicione uma capa <span style="font-weight: lighter !important;">(opcional)</span></label>
            <div class="select-image" :style="stepFunil == 1 ? '' : 'opacity: 0.6;'">
              <div @click="openModal('Upload-Editor')">
                <img
                  :src="
                    coverCampanha
                      ? coverCampanha
                      : 'https://gdigital.s3.amazonaws.com/gdigital/8/imagem-vazia%20%28200%C2%A0%C3%97%C2%A0200%C2%A0px%29.webp'
                  "
                  alt="Imagem preview"
                />
              </div>
              <div>
                <input
                  type="text"
                  v-model="coverCampanha"
                />
                <span @click="openModal('Upload-Editor')">{{
                  coverCampanha
                    ? "Alterar imagem"
                    : "Selecionar imagem"
                }}</span>
              </div>
            </div>
          </b-collapse>
        </b-form-group>
        <b-collapse v-model="collapse2">
          <h3 class="stepFunil" @click="stepFunil == 2 ? '' : changeStep(2)" :style="stepFunil == 2 ? '' : 'cursor:pointer;'">Etapa 02</h3>
          <b-collapse v-model="templatesGrid" accordion="step2">
            <div class="grid-templates">
              <div @click="templateSelected = {name: 'Funil em branco', id: 0};" :class="templateSelected.id == 0 ? 'selected-template' : ''">
                <img src="@/assets/img/emptyFunnel.png" alt=""/>
                <div>
                  <p>Funil em branco</p>
                </div>
              </div>
              <div v-for="(temp, n) in templates" :key="n" @click="templateSelected = {name: temp.title, id: temp.id};" :class="templateSelected.id == temp.id ? 'selected-template' : ''">
                <img v-if="temp.metas.find(meta => meta.meta_key == 'metas') && temp.metas.find(meta => meta.meta_key == 'metas').meta_value" :src="temp.metas.find(meta => meta.meta_key == 'metas').meta_value" alt="Capa do funil" style="object-fit: cover;"/>
                <img v-else src="@/assets/img/emptyFunnel2.png" alt="Capa do funil" style="object-fit: cover;"/>
                <div>
                  <p>{{ temp.title }}</p>
                </div>
                <div style="height: 3px; width: 100%;" v-if="n == templates.length - 1 && templatesGrid && collapse2" v-observe-visibility="stopTemplate ? '' : getTemplates()"></div>
              </div>
            </div>
          </b-collapse>
          <b-collapse v-model="templatesName" accordion="step2">
            <label class="past-sub-title">Template selecionado</label>
            <input
              disabled
              type="text"
              name="Campanha_nome"
              id="Campanha_nome"
              placeholder="Nome do funil"
              v-model="templateSelected.name"
              style="transition: all 0.3s; background-color: transparent !important; width: 60% !important; margin-bottom: 25px !important;"
              :class="stepFunil == 1 ? '' : 'input-off'"
            />
          </b-collapse>
        </b-collapse>
        <BaseButton v-if="stepFunil < 3" @click="criarCampanha(false)" variant="black" style="height: 50px !important;"
          >Continuar
        </BaseButton>
        <BaseButton v-else @click="criarCampanha(true)" variant="primary" style="height: 50px !important;"
          >Criar funil
        </BaseButton>
        <Upload @setImg="setImg" />
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/BaseButton";
//
//
import EmailService from "@/services/resources/EmailService";
const serviceEmail = EmailService.build();
//
import FunnelTemplateService from "@/services/resources/FunnelTemplateService";
const serviceFunnelTemplate = FunnelTemplateService.build();
//
import CampanhaService from "@/services/resources/CampaignService";
const serviceCampanha = CampanhaService.build();
//
import RedirectService from "@/services/resources/RedirectService";
const service = RedirectService.build();
//
import PageListService from "@/services/resources/PageListService";
const PaginaListService = PageListService.build();
//
import FormService from "@/services/resources/FormService";
const serviceForm = FormService.build();
//
import FunnelService from "@/services/resources/FunnelService";
const serviceFunnel = FunnelService.build();
//
import SettingsService from "@/services/resources/SettingsService";
const serviceSettings = SettingsService.build();
// 
import Vue from "vue";
//
import Upload from "../Editor/Upload.vue";

export default {
  components: {
    BaseButton,
    Upload,
  },
  data() {
    return {
      stopCampaign: false,
      validImage: true,
      collapse2: false,
      templatesGrid: true,
      templatesName: true,
      templateSelected: {name: 'Funil em branco', id: 0},
      titleRequired: false,
      titleLength: false,
      titleSpace: false,
      dadosCampanha: {},
      search_page: "",
      pagina: [],
      paginas: [],
      total: 0,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
      loading: false,
      etapa: 0,
      steps: 5,
      stepFunil: 1,
      button: 1,
      nomeCampanha: "",
      options_form: [],
      selectedForm: [],
      selectedCampanha: null,
      template: null,
      templates: [],
      templatePage: 1,
      tipo_Campanha: null,
      coverCampanha: null,
      stop: false,
      stopTemplate: false,
      remetentes: [],
      remetente: [],
    };
  },

  methods: {
    changeStep(n){
      this.stepFunil = n
      if (n == 1) {
        this.validImage = true
        this.collapse2 = false
      } if (n == 2) {
        this.templatesGrid = true
        this.templatesName = false
      }
    },
    openModal(data) {
      this.$bvModal.show(data);
    },
    setImg(img) {
      this.coverCampanha = img;
    },
    validSpace() {
      const regex = /  /;
      this.titleSpace = regex.test(this.nomeCampanha);
    },

    criarCampanha(status = false) {
      if(this.stopCampaign && status){
        return;
      }
      status ? this.stopCampaign = true : null;
      if (this.stepFunil == 1) {
        const regex = /  /;
        if (!this.nomeCampanha) {
          this.titleRequired = true;
          this.titleLength = false;
        } else if (
          this.nomeCampanha.length < 6 ||
          this.nomeCampanha.length > 40 ||
          regex.test(this.nomeCampanha)
        ) {
          this.titleLength = true;
          this.titleRequired = false;
        } else {
          this.titleRequired = false;
          this.titleLength = false;
          if (!this.coverCampanha) {
            this.validImage = false
          }
          this.stepFunil++
          this.collapse2 = true
        }
        return
      } else if (this.stepFunil == 2) {
        this.templatesGrid = false
        this.templatesName = true
        this.stepFunil++
        return
      } else {
        var data = {
          title: this.nomeCampanha,
          status: "active",
          metas: this.coverCampanha,
        };
        if (this.templateSelected.id) {
          data.campaing_id = this.templateSelected.id
        };
      }
      serviceCampanha
        .create(data)
        .then((resp) => {
          if (resp.success == false) {
            setTimeout(() => {
              this.$grToast.toast(
                "Você atingiu o limite máximo de funis!",
                {
                  title: "Criação de funil",
                  variant: "danger",
                  autoHideDelay: 5000,
                  appendToast: true,
                }
              );
            }, 1000);
            history.back();
          } else {
            // this.$store.dispatch("formRequest");
            this.stopCampaign = false;
            this.$router.push({
              name: "CampanhasListaUnica",
              params: {
                id: resp[0].id,
              },
            });
            this.$grToast.toast("Funil criado com sucesso!", {
              title: "Criação de funil",
              variant: "info",
              autoHideDelay: 5000,
              appendToast: true,
            });
            // this.$store.dispatch("formRequest");
          }
        })
        .catch(() => {
          this.$grToast.toast("Erro ao criar o funil!", {
            title: "Criação de funil",
            variant: "danger",
            autoHideDelay: 5000,
            appendToast: true,
          });
      });
    },
    getTemplates(){
      if (this.stopTemplate) {
        return;
      }
      serviceFunnelTemplate
        .read(`?page=${this.templatePage}`)
        .then((resp) => {
          this.templatePage++
          if (resp.data.length < resp.per_page) {
            this.stopTemplate = true;
          }
          this.templates = this.templates.concat(resp.data);
        })
        .catch((err) => {
      });
    },
  },
  computed: {
    verificUserLevel() {
      var user = this.$store.getters.user.user.level;
      if (user === "owner" || user === "admin" || user == "gerent_seller") {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    nomeCampanha: "validSpace",
  },
  created(){
    this.getTemplates()
  }
};
</script>

<style lang="scss" scoped>
.grid-templates {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 25px;
  margin-bottom: 30px;
  border-radius: 10px;
  border: 1px solid var(--white-medium);
  padding: 30px;
  max-height: 475px;
  overflow: hidden;
  * {
    -webkit-user-drag: none;
    user-select: none;
  }
  > div {
    cursor: pointer;
    transition: all 0.3s;
    img {
      border-radius: 10px;
      width: 100%;
      aspect-ratio: 1;
      position: relative;
    }
    p {
      margin: 8px 4px -3px !important;
      color: var(--gray03);
      transition: all 0.3s;
    }
    &:hover {
      filter: brightness(1.05);
    }
    &.selected-template {
      img {
        border: 3px solid var(--greenn);
      }
      p {
        font-weight: 600;
        color: var(--greenn);
      }
    }
  }
}
.past-sub-title {
  font-weight: 400 !important ;
}
.select-image {
  display: flex;
  padding: 10px;
  border: 1px solid #ececec;
  border-radius: 10px;
  width: 60%;
  gap: 10px;
  margin-bottom: 30px;
  transition: all 0.3s;
  &:hover {
    border-color: var(--greenn) !important;
  }
  img {
    height: 60px;
    width: 60px;
    object-fit: cover;
    padding: 5px;
    cursor: pointer;
    border-radius: 10px;
  }
  span {
    font-size: 10px;
    text-transform: uppercase;
    text-align: right;
    padding: 5px;
    padding-bottom: 0px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover {
      color: var(--greenn);
    }
  }
  input {
    font-size: 12px !important;
    height: 30px !important;
    padding: 10px !important;
    margin-top: 5px;
    margin-bottom: 0px !important;
    width: 100%;
  }
  div:last-child {
    width: calc(100% - 80px);
  }
}
.input-off {
  border-radius: 0 !important;
  border-color: transparent !important;
  border-bottom-color: var(--white-medium) !important;
  padding-left: 5px !important;
  color: var(--gray05) !important;
  cursor: pointer;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  transition: all 0.5s ease;
  opacity: 0;
  transform: translateX(30px);
  transform: translateY(-30px);
}
.container-pesquisa {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.borderSelected {
  border: 1px solid #03b86c !important;
}
.paginas-conteudo {
  border: 1px solid #ededf0;
  border-radius: 10px;
}
.paginas-descricao-titulo {
  font-weight: 600;
  font-size: 14px;
  color: var(--gray01);
}
.paginas-descricao {
  padding: 10px 10px;
}
.paginas-conteudo-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.max-container {
  position: absolute;
  top: 100px;
  left: 100px;
  width: calc(100% - 100px);
}
.breadcrumb-Campanha {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--gray01);
  padding-top: 35px;
  margin-left: 35px;
  span {
    color: #81858e;
    cursor: pointer;
  }
}
p {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #81858e;
}
header {
  height: 88px;
  border-bottom: 1px solid #ededf0;
  display: flex;
  justify-content: space-between;
}
.titleError {
  font-size: 12px;
  margin-top: -20px;
  font-weight: 500;
}
.stepFunils-header {
  display: flex;
  gap: 35px;
  padding-top: 35px;
  padding-right: 35px;
}
.header-titulo {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
.options {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #81858e;
  cursor: pointer;
}
.active {
  font-weight: 600 !important;
  color: var(--gray01) !important;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    top: 5px;
    border-radius: 50%;
    left: -10px;
    background: var(--gray01);
  }
}
.titulo {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: var(--gray01);
  margin-bottom: 15px;
}
.Campanha-container {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
}
.box-Campanha {
  border: 1px solid #ededf0;
  border-radius: 10px;
  margin: 15px auto;
  padding: 40px;
}
.stepFunil {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: var(--greenn);
  margin-bottom: 20px;
}
.sub-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: var(--gray01);
  margin-bottom: 10px !important;
}
.Campanha-nome {
  width: 60%;
  margin: 0px 0 13px 0 !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
  input {
    background-color: transparent !important;
  }
}
.cover {
  width: 60%;
  margin: 0px 0 30px 0 !important;
}
.done {
  color: #03b86c;
}
.go-back {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: rgba(247, 247, 247, 0.5);
  position: relative;
  cursor: pointer;
}
.go-back img {
  position: absolute;
  left: 13px;
  top: 13px;
}
.stepFunil2-container {
  position: relative;
}
.stepFunil2-container .go-back {
  position: absolute;
  left: -51px;
  top: -9px;
}
.options-buttons {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
button {
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 0.2px;
  height: 60px;
  padding: 0 42px !important;
  border-radius: 10px;
}
.selected {
  background: transparent !important;
  color: var(--bluee) !important;
  border: 1px var(--bluee) solid !important;
  background: rgba(33, 51, 210, 0.1) !important;
  &:hover {
    transform: scale(1.07) !important;
  }
}
.button-option {
  width: auto;
  color: #777;
  background: #f7f7f7;
  border: 1px #fff solid;
  cursor: pointer;
}
.lock {
  display: flex;
  align-items: center;
  gap: 5px;
}
.lock-icon {
  margin-bottom: 5px;
}
.galeria {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 30px;
  max-height: 410px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: scroll;
}
.galeria::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}
.galeria::-webkit-scrollbar-track {
  background-color: transparent;
}
.galeria::-webkit-scrollbar {
  width: 4px;
  background: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
.card-Campanha img {
  width: 100%;
  cursor: pointer;
}
.Campanha-name {
  padding-bottom: 20px;
  border-bottom: 1px solid #ededf0;
}
.Campanha-name {
  margin-top: 10px;
}
.selectedCard {
  position: relative;
  &::after {
    content: "";
    width: 18px;
    height: 18px;
    position: absolute;
    right: 0;
    top: -20px;
    background: url("../../assets/img/icons/star.svg");
  }
}
.selectedBorder {
  border: 5px solid #03b86c;
  border-radius: 13px;
  position: relative;
}
.selectedCampanhaName {
  color: #03b86c;
}
.remetente {
  margin-bottom: 20px;
}
.img-conteudo {
  width: 100%;
  object-fit: contain;
  // height: 217.42px;
  border-radius: 5px 5px 0 0;
}

.container-text {
  word-break: break-all;
  padding: 10px 0;
  span {
    background: #ededf0;
    border: 5px solid #ededf0;
    border-radius: 10px;
    font-weight: 600;
  }
}
.flex {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ededf0;
  padding: 10px 0;
  gap: 5px;
  h1 {
    color: #ccc;
    font-size: 18px;
  }
  h2 {
    font-size: 18px;
  }
}
.container-infos {
  padding: 20px;
  border-bottom: 1px solid #ededf0;
}
.container-icon-title {
  display: flex;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid #ededf0;
  padding: 10px 20px;
  h1 {
    font-size: 16px;
  }
  .img {
    background: #ededf0;
    border: 5px solid #ededf0;
    border-radius: 50%;
  }
}
.container-info {
  border: 1px solid #ededf0;
  border-radius: 4px;
}
.step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    background: var(--greenn);
    font-size: 16px;
    border: 5px solid var(--greenn);
    border-radius: 4px;
    margin-bottom: 0.5rem !important;
    padding: 5px;
    color: #fff;
  }
  span {
    display: block;
    background: var(--greenn);
    width: 4px;
    height: 65%;
  }
  .dashed {
    display: block;
    background: #ccc;
    width: 4px;
    height: 10%;
    border-left: 1px dashed #fff;
    border-right: 1px dashed #fff;
  }
}

.container-step {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 10px;
}

.container-create {
  display: grid;
  gap: 10px;
  padding: 30px 0;
}

.sequence {
  align-items: center;
  display: flex;
  gap: 35px;
  padding-right: 35px;
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #81858e;
    margin: 0;
    cursor: pointer;
  }
  .active {
    font-weight: 600;
    color: var(--gray01);
  }
  .active::before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 6px;
    border-radius: 100%;
    background-color: var(--gray01);
    position: relative;
    left: -5px;
    top: -1px;
  }
}
.breadcrumb-form {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--gray01);
  margin-left: 35px;
  padding-top: 35px;
  span {
    color: #81858e;
    cursor: pointer;
  }
}
.header {
  height: 88px;
  border-bottom: 1px solid #ededf0;
  display: flex;
  justify-content: space-between;
}

h1 {
  margin-bottom: 0px !important;
}

h2 {
  margin-bottom: 0px !important;
}
</style>

<style lang="scss">
.create-funnel .multiselect--disabled {
  background-color: transparent !important;
  border-color: transparent !important;
  opacity: 1 !important;
  .multiselect__tags {
    border-radius: 0 !important;
    border-color: transparent !important;
    border-bottom-color: var(--white-medium) !important;
    padding-left: 5px !important;
  }
  .multiselect__single {
    color: var(--gray05) !important;
  }
  .multiselect__tags::before {
    background-color: transparent !important;
  }
}
</style>